import React, { createContext, useContext, useState } from "react";
import { CircularProgress, Dialog, DialogContent, makeStyles } from "@material-ui/core";

const LoadingContext = createContext(null);

export function useLoadingGlobal() {
  return useContext(LoadingContext);
}

const useStyles = makeStyles({
  dialogPaperPadding: {
    padding: "2rem"
  },

  backdrop: {
    backgroundColor: "rgba(0,0,0,0.2)"
  }
});

export function LoadingGlobal(props) {
  const classes = useStyles();
  const [isLoadingOpen, setIsLoadingOpen] = useState(false);

  function handleOpen(message) {
    setIsLoadingOpen(true);
  }

  function handleClose() {
    setIsLoadingOpen(false);
  }

  const value = {
    open: handleOpen,
    close: handleClose
  };

  return (
    <LoadingContext.Provider value={value}>
      {props.children}
      <Dialog
        open={isLoadingOpen}
        disableBackdropClick={true}
        BackdropProps={{ invisible: false, className: classes.backdrop }}
        PaperProps={{ elevation: 1, className: classes.dialogPaperPadding }}
      >
        <CircularProgress color="secondary" size={120} thickness={3.6} />
      </Dialog>
    </LoadingContext.Provider>
  );
}
