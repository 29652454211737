import SearchIcon from "@material-ui/icons/Search";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import React from "react";

export const categories = [
  {
    name: "Ne Haber",
    slug: "/kategoriler/ne-haber"
  },
  {
    name: "Kültür Sanat",
    slug: "/kategoriler/kultur-sanat",
    children: [
      {
        name: "Edebiyat",
        slug: "/kategoriler/edebiyat",
        children: [
          {
            name: "Kitap",
            slug: "/kategoriler/kitap"
          },
          {
            name: "Öykü Yazıları",
            slug: "/kategoriler/oyku-yazilari"
          },
          {
            name: "Roman",
            slug: "/kategoriler/roman"
          },
          {
            name: "Şiir",
            slug: "/kategoriler/siir"
          }
        ]
      },
      {
        name: "Sanat",
        slug: "/kategoriler/sanat"
      },
      {
        name: "Sinema",
        slug: "/kategoriler/sinema"
      },
      {
        name: "Müzik",
        slug: "/kategoriler/muzik"
      },
      {
        name: "Fotoğraf",
        slug: "/kategoriler/fotograf"
      },
      {
        name: "Plastik Sanatlar",
        slug: "/kategoriler/plastik-sanatlar"
      },
      {
        name: "Tasarım",
        slug: "/kategoriler/tasarim"
      },
      {
        name: "Tiyatro",
        slug: "/kategoriler/tiyatro"
      }
    ]
  },
  {
    name: "Hayat",
    slug: "/kategoriler/hayat",
    children: [
      {
        name: "İnsan",
        slug: "/kategoriler/insan"
      },
      {
        name: "Şehir",
        slug: "/kategoriler/sehir"
      },
      {
        name: "Yeme İçme",
        slug: "/kategoriler/yeme-icme"
      },
      {
        name: "Eğitim",
        slug: "/kategoriler/egitim"
      },
      {
        name: "Eğlence",
        slug: "/kategoriler/eglence"
      },
      {
        name: "Gezi",
        slug: "/kategoriler/gezi"
      },
      {
        name: "Doğa",
        slug: "/kategoriler/doga"
      },
      {
        name: "Medya",
        slug: "/kategoriler/medya"
      },
      {
        name: "Tarih",
        slug: "/kategoriler/tarih"
      },
      {
        name: "Sağlık",
        slug: "/kategoriler/saglik"
      }
    ]
  },
  {
    name: "Bilim Teknoloji",
    slug: "/kategoriler/bilim-teknoloji"
  },
  {
    name: "Ekonomi",
    slug: "/kategoriler/ekonomi"
  },
  {
    name: "Liste",
    slug: "/kategoriler/liste"
  },
  {
    name: "Söyleşi",
    slug: "/kategoriler/soylesi"
  },
  {
    name: "Öykü",
    slug: "/kategoriler/oyku"
  },
  {
    name: "Video",
    slug: "/kategoriler/video"
  }
];

export const authenticatedMenu = [
  {
    name: "Arama",
    icon: <SearchIcon />,
    slug: "/arama"
  },
  {
    name: "Üyelik",
    icon: <AccountCircleIcon />,
    children: [
      {
        name: "Bilgilerim",
        slug: "/uyelik/bilgilerim"
      },
      {
        name: "Beğendiklerim",
        slug: "/uyelik/begendiklerim"
      },
      {
        name: "Şifre Değiştir",
        slug: "/uyelik/sifre-degistir"
      },
      {
        name: "Çıkış Yap",
        slug: "/uyelik/cikis-yap"
      }
    ]
  }
];

export const notAuthenticatedMenu = [
  {
    name: "Arama",
    icon: <SearchIcon />,
    slug: "/arama"
  },
  {
    name: "Üyelik",
    icon: <AccountCircleIcon />,
    children: [
      {
        name: "Giriş Yap",
        slug: "/uyelik/giris-yap"
      },
      {
        name: "Kayıt Ol",
        slug: "/uyelik/kayit-ol"
      }
    ]
  }
];
