import React, { lazy, Suspense, useState } from "react";
import Topbar from "./Topbar/Topbar";
import { Hidden } from "@material-ui/core";
const Sidebar = lazy(() => import("./Sidebar/Sidebar"));

function Navigation() {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  function handleSidebarToggle() {
    setSidebarOpen(isOpen => !isOpen);
  }

  return (
    <>
      <Topbar onSidebarToggleClick={handleSidebarToggle} />
      <Hidden mdUp>
        <Suspense fallback={<div />}>
          <Sidebar onSidebarClose={handleSidebarToggle} isOpen={isSidebarOpen} />
        </Suspense>
      </Hidden>
    </>
  );
}

export default Navigation;
