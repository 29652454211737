import React, { createContext, useContext, useState } from "react";
import { Snackbar } from "@material-ui/core";

const SnackbarContext = createContext(null);

export function useSnackbarGlobal() {
  return useContext(SnackbarContext);
}

export function SnackbarGlobal(props) {
  const value = {
    open: handleSnackbarOpen,
    close: handleSnackbarClose
  };

  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [message, setMessage] = useState(null);

  function handleSnackbarOpen(message) {
    setMessage(message);
    setIsSnackbarOpen(true);
  }

  function handleSnackbarClose() {
    setIsSnackbarOpen(false);
    setMessage(null);
  }

  return (
    <SnackbarContext.Provider value={value}>
      {props.children}
      <Snackbar
        open={isSnackbarOpen}
        message={message}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      />
    </SnackbarContext.Provider>
  );
}
