import React, { createContext, useContext } from "react";

const FacebookContext = createContext(null);

export function useFacebookGlobal() {
  return useContext(FacebookContext);
}

export function FacebookGlobal({ children }) {
  function share(url) {
    window.FB.ui(
      {
        method: "share",
        href: url
      },
      function(response) {}
    );
  }

  const value = {
    share: share
  };

  return <FacebookContext.Provider value={value}>{children}</FacebookContext.Provider>;
}
