import React, { forwardRef } from "react";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import { Link } from "react-router-dom";

const TopbarMenuButtonItem = forwardRef(({ button, onClick }, ref) => {
  const item = (
    <MenuItem
      component={Link}
      to={button.slug}
      ref={ref}
      onClick={() => onClick()}
      key={button.name}
    >
      <Typography variant="body2">
        <b>{button.name}</b>
      </Typography>
    </MenuItem>
  );

  const childItems =
    button.children &&
    button.children.length > 0 &&
    button.children.map(c => (
      <MenuItem component={Link} to={c.slug} ref={ref} onClick={() => onClick()} key={c.name}>
        <Typography variant="body2">
          <b>- {c.name}</b>
        </Typography>
      </MenuItem>
    ));

  return (
    <>
      {item}
      {childItems}
    </>
  );
});

export default TopbarMenuButtonItem;
