import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

function TopbarTextButton(props) {
  return (
    <Button color="inherit" key={props.button.name} component={Link} to={props.button.slug}>
      <Typography style={{ textTransform: "none" }} variant="body2">
        <b>{props.button.name}</b>
      </Typography>
    </Button>
  );
}

export default TopbarTextButton;
