import React, { lazy, Suspense } from "react";
import { CssBaseline, makeStyles } from "@material-ui/core";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import theme from "./utility/Themes/theme";
import Routes from "./routes/Routes";
import { BrowserRouter } from "react-router-dom";
import Services from "./services/Services";
import Grid from "@material-ui/core/Grid";
import StylesProvider from "@material-ui/styles/StylesProvider";
import Navigation from "./components/Navigation/Navigation";
const Footer = lazy(() => import("./components/Footer/Footer"));
const ScrollToTop = lazy(() => import("./utility/ScrollToTop"));

const useStyles = makeStyles({
  container: {
    minHeight: "100%",
    paddingTop: "4.5rem"
  }
});

function App() {
  const classes = useStyles();

  return (
    <BrowserRouter>
      <Suspense fallback={<div />}>
        <ScrollToTop />
      </Suspense>
      <StylesProvider injectFirst={true}>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <Services>
            <Navigation />
            <Grid
              container
              spacing={0}
              direction="column"
              justify="space-between"
              className={classes.container}
            >
              <Grid item>
                <Routes />
              </Grid>
              <Grid item>
                <Suspense fallback={<div />}>
                  <Footer />
                </Suspense>
              </Grid>
            </Grid>
          </Services>
        </ThemeProvider>
      </StylesProvider>
    </BrowserRouter>
  );
}

export default App;
