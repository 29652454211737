import React, { useEffect, useState } from "react";
import TopbarMenuButton from "./TopbarMenuButton";
import TopbarIconButton from "./TopbarIconButton";
import TopbarTextButton from "./TopbarTextButton";
import Grid from "@material-ui/core/Grid";
import { categories, authenticatedMenu, notAuthenticatedMenu } from "./topbarNavigation";
import { useAuthGlobal } from "../../../globals/AuthGlobal";

function TopbarButtons() {
  const [menuItems, setMenuItems] = useState([]);
  const authGlobal = useAuthGlobal();

  useEffect(() => {
    if (authGlobal.isAuthenticated) setMenuItems(authenticatedMenu);
    else setMenuItems(notAuthenticatedMenu);
  }, [authGlobal.isAuthenticated]);

  const categoryButtons = categories.map(b =>
    b.children && b.children.length > 0 ? (
      <TopbarMenuButton key={b.name} button={b} />
    ) : (
      <TopbarTextButton key={b.name} button={b} />
    )
  );

  const menuButtons = menuItems.map(b =>
    b.children && b.children.length > 0 ? (
      <TopbarMenuButton key={b.name} button={b} />
    ) : (
      <TopbarIconButton key={b.name} button={b} />
    )
  );

  return (
    <Grid container alignItems="center" justify="space-between">
      <Grid item>{categoryButtons}</Grid>
      <Grid item>{menuButtons}</Grid>
    </Grid>
  );
}

export default TopbarButtons;
