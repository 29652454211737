import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import TopbarButtons from "./TopbarButtons";
import { makeStyles } from "@material-ui/styles";
import logo from "../../../assets/images/oggito_logo.png";
import { Hidden } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  appbar: {
    [theme.breakpoints.up("md")]: {
      padding: "0rem 1rem"
    }
  },
  logo: {
    [theme.breakpoints.up("md")]: {
      marginRight: "1rem"
    }
  },
  container: {
    [theme.breakpoints.down("md")]: {
      justifyContent: "space-between"
    },
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-start"
    }
  }
}));

function Topbar({ onSidebarToggleClick }) {
  const classes = useStyles();

  return (
    <AppBar position="fixed" className={classes.appbar} elevation={0}>
      <Toolbar>
        <Grid container className={classes.container}>
          <Hidden mdUp>
            <IconButton onClick={onSidebarToggleClick} color="inherit">
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Button component={Link} to="/" className={classes.logo} color="inherit">
            <img width="105" src={logo} alt="Oggito Logo" />
          </Button>
          <Hidden smDown>
            <Grid item xs>
              <TopbarButtons />
            </Grid>
          </Hidden>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default Topbar;
