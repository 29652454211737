import axios from "axios";
import { useSnackbarGlobal } from "../globals/SnackbarGlobal";
import { useAuthGlobal } from "../globals/AuthGlobal";
import { useAccountService } from "./AccountService";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";

const baseAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 20000,
  responseType: "json",
  headers: { "Cache-Control": "no-cache" }
});

export function useAxiosService() {
  const snackbarGlobal = useSnackbarGlobal();
  const authGlobal = useAuthGlobal();
  const accountService = useAccountService();
  const [token, cancelToken] = useCancelToken();
  const history = useHistory();

  useEffect(() => {
    return function cleanup() {
      cancelToken();
    };
  }, []);

  function send(httpRequest) {
    const accessToken = authGlobal.getAccessToken();
    if (accessToken) baseAxios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

    return new Promise((resolve, reject) => {
      baseAxios(httpRequest)
        .then(response => resolve(response.data.data))
        .catch(async error => {
          let errorMessage;

          // Timeout & Cancellation errors
          if (!error.response) errorMessage = "İstek zaman aşımına uğradı veya iptal edildi.";
          // Authorization errors
          else if (error.response.status === 401) {
            const refreshToken = authGlobal.getRefreshToken();

            if (!refreshToken) {
              history.push("/uyelik/giris-yap");

              errorMessage = "Oturum zaman aşımına uğradı. Lütfen tekrar giriş yapınız.";
            } else {
              try {
                const getAccessTokenResponse = await accountService.GetAccessToken(
                  token,
                  refreshToken
                );

                authGlobal.login(
                  getAccessTokenResponse.refreshToken,
                  getAccessTokenResponse.accessToken
                );

                baseAxios.defaults.headers.common[
                  "Authorization"
                ] = `Bearer ${getAccessTokenResponse.accessToken}`;

                error.config.headers[
                  "Authorization"
                ] = `Bearer ${getAccessTokenResponse.accessToken}`;

                // Send original request one more time!
                return resolve(send(error.config));
              } catch (error) {
                errorMessage = "Oturum zaman aşımına uğradı. Lütfen tekrar giriş yapınız.";

                authGlobal.logout();

                history.push("/uyelik/giris-yap");
              }
            }
          }
          // Service handled errors
          else errorMessage = Object.values(error.response.data["Errors"])[0].Message;

          // We don't show messages which are thrown by cancellation token.
          if (!axios.isCancel(error)) snackbarGlobal.open(errorMessage);

          return reject(errorMessage);
        });
    });
  }

  return { send };
}

export function useCancelToken() {
  const cancelTokenSource = axios.CancelToken.source();

  return [cancelTokenSource.token, cancelTokenSource.cancel];
}
