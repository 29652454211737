import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { KeyboardArrowDown } from "@material-ui/icons";
import Menu from "@material-ui/core/Menu";
import makeStyles from "@material-ui/styles/makeStyles/makeStyles";
import TopbarMenuButtonItem from "./TopbarMenuButtonItem";
import IconButton from "@material-ui/core/IconButton";
import { grey } from "@material-ui/core/colors";
import { colors } from "../../../utility/Themes/colors";

const useStyles = makeStyles(() => ({
  button: {
    textTransform: "none"
  },

  // Fak this.
  menuPaper: {
    color: `${grey[200]} !important`,
    backgroundColor: `${colors.primary} !important`
  }
}));

function TopbarMenuButton({ button }) {
  const classes = useStyles();

  const [anchor, setAnchor] = useState(null);

  function handleMenuClick(event) {
    setAnchor(event.currentTarget);
  }

  function handleMenuClose() {
    setAnchor(null);
  }

  return (
    <>
      {!button.icon ? (
        <Button color="inherit" onClick={event => handleMenuClick(event)}>
          <Typography className={classes.button} variant="body2">
            <b>{button.name}</b>
          </Typography>
          <KeyboardArrowDown />
        </Button>
      ) : (
        <IconButton color="inherit" key={button.name} onClick={event => handleMenuClick(event)}>
          {button.icon}
        </IconButton>
      )}
      <Menu
        open={Boolean(anchor)}
        anchorEl={anchor}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        getContentAnchorEl={null}
        onClose={() => handleMenuClose()}
        PaperProps={{ className: classes.menuPaper }}
      >
        {button.children.map(b => (
          <TopbarMenuButtonItem key={b.name} button={b} onClick={handleMenuClose} />
        ))}
      </Menu>
    </>
  );
}

export default TopbarMenuButton;
