import React from "react";
import { useAuthGlobal } from "../globals/AuthGlobal";
import { Redirect, Route } from "react-router-dom";

function PrivateRoute({ component: Component, ...rest }) {
  const authGlobal = useAuthGlobal();

  return (
    <Route
      {...rest}
      render={props =>
        authGlobal.isAuthenticated ? <Component {...props} /> : <Redirect to="/uyelik/giris-yap" />
      }
    />
  );
}

export default PrivateRoute;
