import React from "react";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";

function TopbarIconButton({ button }) {
  return (
    <IconButton color="inherit" key={button.name} component={Link} to={button.slug}>
      {button.icon}
    </IconButton>
  );
}

export default TopbarIconButton;
