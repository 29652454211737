import { createMuiTheme } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { colors } from "./colors";

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      "Montserrat",
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Arial",
      "sans-serif"
    ].join(",")
  },

  palette: {
    type: "light",
    background: {
      default: grey[200]
    },
    primary: {
      main: grey[900]
    },
    secondary: {
      main: grey[700]
    },
    tonalOffset: 0.1
  },

  mixins: {
    toolbar: {
      minHeight: "4.5rem"
    }
  },

  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: colors.primary
      }
    },

    MuiToolbar: {
      root: {
        color: grey[200],
        backgroundColor: colors.primary
      }
    },

    MuiDrawer: {
      root: {
        color: grey[200]
      },
      paper: {
        color: grey[200],
        backgroundColor: colors.primary
      }
    },

    MuiButton: {
      contained: {
        boxShadow: "none",
        textTransform: "none",
        "&:hover": {
          boxShadow: "none"
        },
        "&:active": {
          boxShadow: "none"
        }
      }
    },

    MuiSkeleton: {
      root: {
        backgroundColor: grey[300]
      }
      // wave: {
      //   "&::after": {
      //     background: `linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.15), transparent)`
      //   }
      // }
    }
  },

  shape: {
    // borderRadius: 0
  }
});

export default theme;
